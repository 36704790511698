import { Divider, Form, Image, Input } from "antd";
import { useEffect } from "react";

import { LoadingOutlined} from "@ant-design/icons";
import "../mobile/loadding.css"
import "../mobile/Mine.css"
export default function loading() {
    return (
        <>
            <div className="loaddingBox">
                <LoadingOutlined style={{color:"white"}}/>
                <div className="loaddingText" style={{color:"white"}}>加载中</div>
            </div>
        </>
    );
}