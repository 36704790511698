import { Button, Form, Input, message, Modal } from "antd";
import { useSelector } from "react-redux";
import { selectJsonRequest } from "../../state/global";
import { useNavigate } from "react-router-dom";
import { useLocalStorageState, useRequest } from "ahooks";

export function ChangePassword() {
  const jsonClient = useSelector(selectJsonRequest);
  const navigate = useNavigate();

  const [userStore, setUserStore] = useLocalStorageState<{
    id: string;
    name: string;
    telephone?: string;
  }>("user");

  const [form] = Form.useForm();
  const changeRes = useRequest(
    (req) =>
      jsonClient.post("/v1/docctor/password", req).then((res) => {
        if (res?.data) {
          return res;
        } else {
          throw res;
        }
      }),
    {
      manual: true,
      loadingDelay: 300,
      onSuccess() {
        message.success("密码修改成功！");
        Modal.destroyAll();
        setUserStore(undefined);
        navigate("/login", { replace: true });
      },
      onError(error, params) {
        message.error(error?.message || "修改密码失败");
      },
    },
  );

  const onFinish = (values: any) => {
    // 模拟提交表单数据到服务器
    console.log("Received values of form: ", values);
    changeRes.run({
      id: userStore?.id,
      oldPassword: values.oldPassword,
      password: values.password,
    });
  };

  return (
    <Form
      form={form}
      name="password_change_form"
      onFinish={onFinish}
      autoComplete="off"
      labelCol={{ span: 7 }}
    >
      <Form.Item
        label="旧密码"
        name="oldPassword"
        rules={[
          {
            required: true,
            message: "请输入旧密码!",
          },
        ]}
      >
        <Input.Password placeholder="请输入旧密码" />
      </Form.Item>

      <Form.Item
        label="新密码"
        name="password"
        rules={[
          {
            required: true,
            message: "请输入新密码!",
          },
        ]}
      >
        <Input.Password placeholder="请输入新密码" />
      </Form.Item>

      <Form.Item
        label="确认新密码"
        name="confirmNewPassword"
        dependencies={["password"]}
        rules={[
          {
            required: true,
            message: "请再次输入新密码!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("两次输入的新密码不一致!"));
            },
          }),
        ]}
      >
        <Input.Password placeholder="请再次输入新密码" />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          loading={changeRes.loading}
        >
          提交
        </Button>
      </Form.Item>
    </Form>
  );
}
