import "./App.css";
import "lib-flexible/flexible";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import { Login } from "./pages/Login";
import { useEffect } from "react";
import Detail from './pages/Detail'

import List from './pages/List'
function App() {
  const navigate = useNavigate();
    // 获取 User-Agent 字符串
    const userAgent = window.navigator.userAgent;
    // 判断是否是手机
    const isMobile = /Mobile/i.test(userAgent);

    useEffect(() => {
    if (localStorage.getItem("user")&& isMobile) {
        navigate("/list");
    }else if (localStorage.getItem("user")) {
        navigate("/");
    }
    else{
        navigate("/login");
    }
    // if(isMobile && localStorage.getItem("user")){
    //     navigate("/list");
    // }else if (localStorage.getItem("user")) {
    //     navigate("/");
    // } else {
    //   navigate("/login");
    // }
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/Detail" element={<Detail />}></Route>
      <Route path="/list" element={<List />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default App;
