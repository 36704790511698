import { Button, Checkbox, Form, Input, message,Row,Col} from "antd";
// import css from "./login.module.css";
import css from "./mobile/login.notMobile.module.css";
import { useLocalStorageState, useRequest } from "ahooks";
import { useSelector } from "react-redux";
import { selectJsonRequest } from "../state/global";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

export const Login = () => {
  const jsonClient = useSelector(selectJsonRequest);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const checked = Form.useWatch("remember", form);
  const [mode, setMode] = useState<"login" | "register">("login");

  const [userStore, setUserStore] = useLocalStorageState<{
    id: string;
    name: string;
    telephone?: string;
  }>("user");

  const loginRes = useRequest(
    ({ path, ...req }) =>
      jsonClient.post(path, req).then((res) => {
        if (res?.data) {
          return res;
        } else {
          throw res;
        }
      }),
    {
      manual: true,
      loadingDelay: 300,
      onSuccess(data, params) {
        if (mode === "login") {
          // 登录成功
          setUserStore(data.data);
          navigate("/home", { replace: true });
        } else {
          form.resetFields();
          setMode("login");
        }
      },
      onError(error, params) {
        message.error(error?.message || "登录失败");
      },
    },
  );

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    if (mode === "login") {
      loginRes.run({
        path: "/v1/docctor/login",
        account: values.account,
        password: values.password,
      });
    } else {
      loginRes.run({
        path: "/v1/docctor/register",
        account: values.account,
        password: values.password,
        name: values.name,
        telephone: values.telephone,
      });
    }
  };

  return (
    <div className={css.page}>
      <h1 style={{ textAlign: "center" }}>
        {mode === "login" ? "登录" : "注册"}
      </h1>
      <Form
        style={{ width: "320px" }}
        form={form}
        name="login"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        labelAlign="right"
        labelCol={{ span: 5 }}
      >
        <Form.Item
          name="account"
          label="用户名"
          rules={[
            { required: true, message: "请输入您的用户名!" },
            { min: 2, max: 20, message: "用户名长度为2-20位" },
          ]}
        >
          <Input
            placeholder="请输入您的用户名"
            name="username"
            autoComplete={checked === false ? "off" : "username"}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="密码"
          rules={[{ required: true, message: "请输入您的密码!" }]}
        >
          <Input.Password
            placeholder="请输入您的密码"
            name="password"
            autoComplete={checked === false ? "off" : "current-password"}
          />
        </Form.Item>

        {mode === "register" ? (
          <>
            <Form.Item
              name="password2"
              label="再次输入密码"
              rules={[
                { required: true, message: "请再次输入您的密码!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("两次输入的密码不一致!"));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="请输入您的密码" />
            </Form.Item>

            <Form.Item
              name="name"
              label="姓名"
              rules={[
                { required: true, message: "请输入您的姓名!" },
                { min: 2, max: 20, message: "姓名长度为2-20位" },
              ]}
            >
              <Input placeholder="请输入您的姓名" />
            </Form.Item>
            <Form.Item
              name="telephone"
              label="手机号码"
              rules={[
                { pattern: /^1[3-9]\d{9}$/, message: "手机号码格式不正确" },
              ]}
            >
              <Input placeholder="请输入您的手机号码" />
            </Form.Item>
          </>
        ) : (
          <Form.Item
            // wrapperCol={{
            //   offset: 5,
            // }}
          >
              <Row>
                  <Col
                      xs = {{ span: 24, offset: 0 }}
                      lg = {{ span: 19, offset: 5 }}
                      sm = {{ span: 19, offset: 5 }}
                  >
                      <Form.Item name="remember" valuePropName="checked" noStyle>
                          <Checkbox>记住密码</Checkbox>
                      </Form.Item>
                  </Col>
              </Row>
          </Form.Item>
        )}
        <Form.Item
          // wrapperCol={{
          //   offset: 5,
          // }}
        >
            <Row>
                <Col
                    xs = {{ span: 24, offset: 0 }}
                    lg = {{ span: 19, offset: 5 }}
                    sm = {{ span: 19, offset: 5 }}
                >
                    <Button
                    loading={loginRes.loading}
                    type="primary"
                    htmlType="submit"
                    block
                >
                    {mode === "login" ? "登录" : "注册并登录"}
                </Button>
                    <Link to="#" style={{ display: "block", marginTop: "10px" }}></Link>
                </Col>
            </Row>
            <div className={css.cs}>

            </div>

          {/*          <Button
            type="link"
            onClick={() => setMode(mode === "login" ? "register" : "login")}
          >
            {mode === "login" ? "没有账号去注册" : "切换登录"}
          </Button>*/}

        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
