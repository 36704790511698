
import { Provider } from "react-redux";
import { store } from "./store";
import zhCN from 'antd/es/locale/zh_CN'
import React from "react";
import { ConfigProvider } from "antd";

export default function GlobalWrap({ children, ...props }: any) {
    const childrenWithProps = React.Children.map(children, child => {
        return React.cloneElement(child, props)
    })

    return <ConfigProvider locale={zhCN}>
        <Provider store={store}>
            {childrenWithProps}
        </Provider>
    </ConfigProvider>
}