import { createSlice } from '@reduxjs/toolkit';
import JsonRequest from '../components/jsonRequest'

const user = JSON.parse(sessionStorage.getItem("sign-in-user") || "null")

const initialState = {
    // 当前用户
    user: user,
    // 导航栏当前激活的key
    navActiveKey: null,
    // json请求客户端
    jsonRequest: JsonRequest.tokenClient(user),
    // 缓存初始化数据，用于加快第二次进入的速度
    cacheData: {}
};

export const counterSlice = createSlice({
    name: 'global',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        // 设置当前活跃的导航项
        navActive: (state, action) => {
            state.navActiveKey = action.payload
        },

        // 用户登录
        signIn: (state, action) => {
            sessionStorage.setItem("sign-in-user", JSON.stringify(action.payload))
            return { ...state, user: action.payload, jsonRequest: JsonRequest.tokenClient(state.user) }
        },

        // 用户退出登录
        signOut: (state) => {
            localStorage.setItem("sign-in-user", "null")
            localStorage.setItem("current-account", "null")
            return { ...state, user: null, account: null }
        },

        // 设置客户端
        setClient: (state, action) => {
            return { ...state, jsonRequest: action.payload }
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    // extraReducers: (builder) => {
    //     builder
    //         .addCase(incrementAsync.pending, (state) => {
    //             state.status = 'loading';
    //         })
    //         .addCase(incrementAsync.fulfilled, (state, action) => {
    //             state.status = 'idle';
    //             state.value += action.payload;
    //         });
    // },
});


//解构赋值语法，将 counterSlice.actions 对象中的属性分别赋值给了相应的常量
export const { navActive: NavActive, signIn, signOut, setClient, } = counterSlice.actions;

//选择器函数，收 state 参数并从 state.global 对象中获取相应的属性值
export const selectUser = (state: { global: { user: any; }; }) => state.global.user;
export const selectNavActiveKey = (state: { global: { navActiveKey: any; }; }) => state.global.navActiveKey;
export const selectJsonRequest = (state: { global: { jsonRequest: any; }; }): JsonRequest => state.global.jsonRequest;


export default counterSlice.reducer;