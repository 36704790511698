import React, {useRef, useState,useEffect} from 'react';
import { useInfiniteScroll } from 'ahooks';
import {selectJsonRequest} from "../../state/global";
import { useSelector } from "react-redux";
import {useSearchParams} from "react-router-dom"

import {Progress} from "antd";
import ReactTimeAgo from "react-time-ago";
import { useNavigate } from "react-router-dom";

import '../mobile/Diagnosis.css'

interface Result {
    list: string[];
    nextId: string | undefined;
}

const resultData = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13'];


export default function Test ({ selectActive }: any){
    const [searchParams] = useSearchParams();
    console.log(searchParams.get("key"))
    let key:string|null = searchParams.get("key");
    const jsonClient = useSelector(selectJsonRequest);
    const [page,setPage]=useState(1)
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const { data, loading, loadMore, loadingMore, noMore } = useInfiniteScroll(
        (d) =>{
            console.log(d)
            //d.nextid就是一页最后一个的id
            return getLoadMoreList(d?.nextId, 15)} ,
        {
            target: ref,
            isNoMore: (d) => d?.list.length == d?.total,
        },
    );
    useEffect(()=>{
         key = searchParams.get("key");

    },[selectActive])
    const getLoadMoreList = async (nextId: string | undefined, limit: number)=> {

        let start = 0;
        if (nextId) {
            //如果存在那么返回等于nextid的 开始位置,也就是找到了位置过后设为开始位置
            start = resultData.findIndex((i) => i === nextId);
        }

        const end = start + limit;
        const list = resultData.slice(start, end);
        // 截取开始到结束的数据
        const nId = resultData.length >= end ? resultData[end] : undefined;
        console.log(nId);
        let data1 = await jsonClient.get("/v1/inquiry/record", {
            pageNumber: page,
            pageSize: 9,
            diagnoseStatus: key == "0"
        });
        setPage(page+1)
        console.log(data1)
        return {
            list:data1.data.list,
            nextId:data1.data.list[data1.data.list.length-1].inquiryRecordId,
            total:data1.data.total
        }
        //nid就是结束的位置
        // return new Promise((resolve) => {
        //     setTimeout(() => {
        //         resolve({
        //             list,
        //             nextId: nId,
        //         });
        //     }, 1000);
        // });
    }
    const navigateToDetail =(id:string)=>{
        // console.log(id)
        navigate(`/Detail?id=${id}` )
    }
    return (
        <div ref={ref} className="infoBox">
            <div className="detailTitle">
                <div className="title">
                    知源问诊单
                </div>
            </div>
            {loading ? (
                <div className="noMore">加载中</div>
            ) : (
                <div>
                    {data?.list?.map((item, index) => {
                        //  {item.inquiryRecordId}
                        return <div className="item" key={index} onClick={() => {
                            navigateToDetail(item.inquiryRecordId)
                        }}>
                            <div className="imageLeft">
                                {/*<div></div>*/}
                                <img src={item.headUrl}/>
                                <div>{item.nickName}</div>
                            </div>
                            <div className="center">
                                <div>{item.name + "，" + (item.sex == "man" ? '男' : '女') + "，" + item.age + "岁"}
                                </div>
                                <div>
                                    <Progress percent={Math.trunc(item.rate * 100)} size="small"/>
                                </div>
                            </div>
                            <div className="right">
                                <div>
                                    <ReactTimeAgo date={item.createTime}/>
                                </div>
                                <div className={key == "1" ? 'show' : 'notShow'}>
                                    {item.createTime}
                                </div>
                                <div className={key == "0" ? 'show' : 'notShow'}>
                                    医生：<span>{item?.doctorName ? item.doctorName:"-----"}</span>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            )}
            <div style={{marginTop: 8}}>
                {!noMore && (
                    // <button type="button" onClick={loadMore} disabled={loadingMore}>
                        <div className="noMore" >{loadingMore ? '加载更多' : '加载更多'}</div>
                    // </button>
                )}
                {(noMore && data) && <div
                    className="noMore">没有更多了哦~</div>}
                <div className={data? 'notShow' : 'noMoreAll'}>没有更多了哦{data?.list.length}~</div>
            </div>
        </div>
    );
};