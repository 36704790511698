import { Button, Form, Image, Input, message } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AliyunOSSUpload } from "../components/AliyunOSSUpload";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectJsonRequest } from "../state/global";
import { LeftOutlined } from "@ant-design/icons";
import "./mobile/detail.css";
import jsonRequest from "../components/jsonRequest";
import { useLocalStorageState } from "ahooks";
import Loading from "./components/Loading";

export default function Detail() {
  const [searchParams] = useSearchParams();
  const id: string | null = searchParams.get("id");
  const [data, setData]: any = useState({});
  const jsonClient: jsonRequest = useSelector(selectJsonRequest);
  const [form] = Form.useForm();
  const [fourDiagnosis, setfourDiagnosis]: any = useState("");
  const [illnessName, setillnessName]: any = useState("");
  const [diagnoseResult, setdiagnoseResult]: any = useState("");
  const [resolutionDisease, setresolutionDisease]: any = useState("");
  const [otherQuestion,setotherQuestion]: any =useState("")
  const [prescriptionPictures, setprescriptionPictures]: any = useState([]);
  const navigate = useNavigate();
  const [userStore, setUserStore] = useLocalStorageState<{
    id: string;
    name: string;
    telephone?: string;
  }>("user");
  const { TextArea } = Input;
  const [loadding, setLoadding]: any = useState(false);
  useEffect(() => {
    getData();
    return () => {};
  }, []);
  const getData = async () => {
    setLoadding(true);
    let data = await jsonClient.get(`/v1/inquiry/record/detail/${id}`, {});
    console.log(data.data);
    setData(data.data);
    setLoadding(false);
    setfourDiagnosis(data.data.fourDiagnosis);
    setillnessName(data.data.illnessName);
    setdiagnoseResult(data.data.diagnoseResult);
    setresolutionDisease(data.data.resolutionDisease);
    setotherQuestion(data.data.otherQuestion)
    setprescriptionPictures(
      data.data.prescriptionPictures?.split(",").map((url: string) => {
        return {
          uid: url,
          url: url,
          name: url,
          status: "done",
        };
      }),
    );
  };
  const goBack = () => {
    navigate(-1);
  };
  const submitData = () => {
    let data2 = {
      fourDiagnosis,
      illnessName,
      diagnoseResult,
      resolutionDisease,
      prescriptionPictures,
      otherQuestion
    };
    let data1 = { ...data };
    let result = Object.assign(data1, data2);
    jsonClient
      .post(`/v1/inquiry/record/result`, {
        ...result,
        doctorName: userStore?.name,
        doctorId: userStore?.id,
        inquiryRecordId: id,
        prescriptionPictures: result.prescriptionPictures
          ?.map((item: any) => {
            if (item.url?.includes("https://")) {
              return item.url;
            }
            return (
              "https://tcm-applet.zhi-yuan.net/v1/aliyun/authorization?objectKey=" +
              item.url
            );
          })
          .join(","),
      })
      .then(() => {
        message.success("保存成功");
        goBack();
        // close();
        // form.resetFields();
      });
  };
  return (
    <>
      {loadding && <Loading />}

      <div className="detailBox">
        <div className="detailTitle">
          <LeftOutlined
            className="icon-detail"
            onClick={() => {
              goBack();
            }}
          />
          <div className="title">问诊单</div>
        </div>
        <div className="detailInfo">
          <div className="infoBox1">
            <div className="infoTitle">基本信息</div>
            <div className="littileBox">
              <div style={{ whiteSpace: "nowrap" }}>个人信息：</div>
                <b>
                  {data?.name}，{data?.sex == "man" ? "男" : "女"}，{data.age}岁，
                {data.height}厘米，{data.weight}公斤，
                  高压（{data.highPressure?  data.highPressure+'mmHg':'无'}
                ）,低压（{data.lowPressure ?  data.lowPressure+'mmHg':'无'}）
              </b>
            </div>
            <div className="littileBox">
              <div style={{ whiteSpace: "nowrap" }}> 既往病史：</div><b>{data.medicalHistory || "无"}</b>
            </div>
            <div className="littileBox">
              <div style={{ whiteSpace: "nowrap" }}>过敏史、遗传史、手术史：</div><b>{data.medicalHistory || "无"}</b>
              </div>
            {data?.illnessDetail?.templateName?.indexOf("女") > -1 && (
              <div className="littileBox">
                <div style={{ whiteSpace: "nowrap" }}>是否妊娠哺乳期：</div><b>{data.medicalHistory || "无"}</b>
                </div>
            )}
            <div className="infoTitle">病历描述</div>
            <div className="littileBox">
              <div style={{ whiteSpace: "nowrap" }}>描述：</div>
              <b>{data.symptomDescription}</b>
            </div>
            <div className="littileBox">
              <div style={{ whiteSpace: "nowrap" }}>舌诊照片：</div>
              {data?.aboveTonguePicture && (
                <Image
                  style={{ border: "5px solid #fff", borderRadius: 10 }}
                  width={80}
                  height={80}
                  src={data.aboveTonguePicture}
                ></Image>
              )}
              {data?.belowTonguePicture && (
                <Image
                  style={{ border: "5px solid #fff", borderRadius: 10 }}
                  width={80}
                  height={80}
                  src={data.belowTonguePicture}
                ></Image>
              )}
            </div>
            <div className="littileBox">
              <div style={{ whiteSpace: "nowrap" }}>面部病患处照片：</div>
              {data?.illnessPicture
                ?.split(",")
                .map(
                  (i: any) =>
                    i && (
                      <Image
                        style={{ border: "5px solid #fff", borderRadius: 10 }}
                        key={i}
                        width={80}
                        height={80}
                        src={i}
                      ></Image>
                    ),
                )}
            </div>
            <div className="littileBox">
              <div style={{ whiteSpace: "nowrap" }}>就诊病例用药情况：
              </div>
                {data?.medicalHistoryUrl
                    ?.split(",")
                .map(
                  (i: any) =>
                    i && (
                      <Image
                        style={{ border: "5px solid #fff", borderRadius: 10 }}
                        key={i}
                        width={80}
                        height={80}
                        src={i}
                      ></Image>
                    ),
                )}
            </div>
            {/*<div>{data?.typeAnswers}</div>*/}
            {!!data?.typeAnswers?.length && (
              <div>
                <div className="infoTitle">病症详情</div>
                {data?.typeAnswers?.map((item: any) => (
                  <div
                    className="littileBoxl"
                    style={{
                      display: "flex",
                      flexFlow: "row wrap",
                    }}
                    key={item.typeId}
                  >
                    <b style={{ whiteSpace: "nowrap" }}>{item.typeName}：</b>
                    {item.answers.map((a: any) => {
                      return (
                        <>
                          <span
                            style={{
                              wordWrap: "break-word",
                              marginRight: "15px",
                            }}
                          >
                            {!a.hideTitle && <span>{a.question}：</span>}
                            <b>{a.answer}</b>
                          </span>
                        </>
                      );
                    })}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="submitBox">
            <div className="infoTitle">辩证结果</div>
            <div className="txetSubmit">
              <div className="leftText">辩证：</div>
              <TextArea
                  rows={1}
                  autoSize={{minRows: 1, maxRows: 5}}
                  placeholder="请输入辩证"
                  value={illnessName}
                  onChange={(e) => {
                    setillnessName(e.target.value);
                  }}
              />
            </div>
            <div className="txetSubmit">
              <div className="leftText">分析：</div>
              <TextArea
                  rows={1}
                  autoSize={{minRows: 1, maxRows: 5}}
                  placeholder="请输入分析"
                  value={diagnoseResult}
                  onChange={(e) => {
                    setdiagnoseResult(e.target.value);
                  }}
              />
            </div>
            <div className="txetSubmit">
              <div className="leftText">四诊：</div>
              <TextArea
                  rows={1}
                  autoSize={{minRows: 1, maxRows: 5}}
                  placeholder="请输入四诊"
                  value={fourDiagnosis}
                  onChange={(e) => {
                    setfourDiagnosis(e.target.value);
                  }}
              />
            </div>
            <div className="txetSubmit">
              <div className="leftText">辩病：</div>
              <TextArea
                  rows={1}
                  autoSize={{minRows: 1, maxRows: 5}}
                  placeholder="请输入辩病"
                  value={resolutionDisease}
                  onChange={(e) => {
                    setresolutionDisease(e.target.value);
                  }}
              />
            </div>
            <div className="txetSubmit">
              <div className="leftText">其他问题：</div>
              <TextArea
                  rows={1}
                  autoSize={{minRows: 1, maxRows: 5}}
                  placeholder="请输入其他问题"
                  value={otherQuestion}
                  onChange={(e) => {
                    setotherQuestion(e.target.value);
                  }}
              />
            </div>
            <div className="txetSubmit">
              <div className="leftText">
                <div style={{whiteSpace: "nowrap"}}>处方：</div>
              </div>
              <AliyunOSSUpload
                  value={prescriptionPictures}
                  onChange={(e) => {
                    setprescriptionPictures(e);
                  }}
              />
            </div>
          </div>
        </div>
        <div className="bottomBoxl">
          <Button
              onClick={() => {
                goBack();
              }}
          >
            取消
          </Button>
          <Button
              type="primary"
              style={{marginLeft: 20}}
              onClick={() => {
                submitData();
              }}
          >
            确定
          </Button>
        </div>
        {/*如果需要变成form*/}
        {/*<Form*/}
        {/*    labelCol={{ span: 8 }}*/}
        {/*    size="small"*/}
        {/*    wrapperCol={{ span: 14 }}*/}
        {/*    form={form}*/}
        {/*    labelWrap*/}
        {/*>*/}
        {/*<Divider>辩证结果</Divider>*/}
        {/*    <div style={{ display: "flex" }}>*/}
        {/*        <div style={{ flex: 1 }}>*/}
        {/*            <Form.Item label="四诊" name="fourDiagnosis">*/}
        {/*                <Input.TextArea autoSize style={{ minHeight: 33 }} />*/}
        {/*            </Form.Item>*/}
        {/*            <Form.Item label="辨病" name="resolutionDisease">*/}
        {/*                <Input.TextArea autoSize style={{ minHeight: 33 }} />*/}
        {/*            </Form.Item>*/}
        {/*            <Form.Item label="处方" name="prescriptionPictures">*/}
        {/*                <AliyunOSSUpload />*/}
        {/*            </Form.Item>*/}
        {/*            <Form.Item label="辩证" name="illnessName">*/}
        {/*                <Input.TextArea autoSize style={{ minHeight: 33 }} />*/}
        {/*            </Form.Item>*/}
        {/*            <Form.Item label="分析" name="diagnoseResult">*/}
        {/*                <Input.TextArea autoSize style={{ minHeight: 33 }} />*/}
        {/*            </Form.Item>*/}
        {/*            <Form.Item label="其他问题" name="otherQuestion">*/}
        {/*                <Input.TextArea autoSize style={{ minHeight: 88 }} />*/}
        {/*            </Form.Item>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</Form>*/}
      </div>
    </>
  );
}
