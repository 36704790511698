import { useSelector } from "react-redux";
import { selectJsonRequest } from "../state/global";
import { useEffect, useState } from "react";
import {
    Avatar,
    Button,
    Divider,
    Form,
    message,
    Modal,
    Popover,
    Progress,
    Table,
    Tag,
} from "antd";
import dayjs from "dayjs";
import GlobalWrap from "../state/globalWrap";
import Detail from "./components/Detail";
import ReactTimeAgo from "react-time-ago";
import TimeAgo from "javascript-time-ago";
import zh from "javascript-time-ago/locale/zh-Hans-MO.json";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import "./mobile/list.css";
import { useLocalStorageState } from "ahooks";
import {useNavigate, useSearchParams} from "react-router-dom";
import { ChangePassword } from "./components/ChangePassword";
import Diagnosis from "./components/Diagnosis";
import Test from "./components/test";
import Diagnosis1 from "./components/Diagnosis1";
import Mine from "./components/Mine";
TimeAgo.addDefaultLocale(zh);

let _page = 1;
let _pageSize = 15;

export default function List() {
    const [searchParams] = useSearchParams();
    console.log(searchParams.get("key"))
    const key:string|null = searchParams.get("key");
    const jsonClient = useSelector(selectJsonRequest);
    const [data, setData]: any = useState();
    const [loading, setLoading] = useState(true);
    const [selectActive, setSelectActive] = useState(1);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [userStore, setUserStore] = useLocalStorageState<{
        id: string;
        name: string;
        telephone?: string;
    }>("user");

    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        // 判断是否是手机
        const isMobile = /Mobile/i.test(userAgent);

        if(!isMobile){
            navigate("/");
            return ;
        }

    }, []);

    // 点击打开详情

    // 点击打开详情

    const handleDelete = async (inquiryRecordId: number) => {
        let data = await jsonClient.delete(
            `/v1/inquiry/record/${inquiryRecordId}`,
            {},
        );
        if (data.data === true) message.success("删除成功");

        // handlePageTo(_page, _pageSize);
    };
    return (
        <div className='box'>
            {/*{( selectActive == 0) && (<Diagnosis selectActive={selectActive} ></Diagnosis>)}*/}
            {(selectActive == 0) && <Test selectActive={selectActive} ></Test>}
            {(selectActive == 1) && <Test selectActive={selectActive} ></Test>}
            {/*{( selectActive == 1) && (<Diagnosis1 selectActive={selectActive} ></Diagnosis1>)}*/}
            {/*<div className={selectActive == 1 ||selectActive == 0? 'show':'notShow'}>*/}
            {/*    */}
            {/*</div>*/}
            <div className={selectActive == 2 ? 'show':'notShow'}>
                <Mine></Mine>
            </div>
            <div className="bottomBox">
                <div  className={key == "0" ? 'active':''}  onClick={()=>{
                    setSelectActive(0);
                    navigate("/List?key=0")
                }}>
                    已诊断
                </div>
                <div  className={key == "1" ? 'active':''}  onClick={()=>{
                    setSelectActive( 1);
                    navigate("/List?key=1")
                }}>
                    未诊断
                </div>
                <div className={key == "2"? 'active':''} onClick={()=>{
                    setSelectActive( 2)
                    navigate("/List?key=2")

                }}>
                    我的
                </div>
            </div>
        </div>
    );
}
