import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { GetProp, message, Upload, UploadFile, UploadProps, Image } from "antd";
import { useSelector } from "react-redux";
import { selectJsonRequest } from "../state/global";

interface OSSDataType {
  dir: string;
  expire: string;
  host: string;
  accessId: string;
  policy: string;
  signature: string;
}

interface AliyunOSSUploadProps {
  value?: UploadFile[];
  onChange?: (fileList: UploadFile[]) => void;
}

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
export const AliyunOSSUpload = ({ value, onChange }: AliyunOSSUploadProps) => {
  const [OSSData, setOSSData] = useState<OSSDataType>();
  const jsonClient = useSelector(selectJsonRequest);

  const getOSSData = async () => {
    const res = await jsonClient.get(`/v1/aliyun/oss/picture`, {});
    return {
      dir: res.data.dir,
      expire: res.data.expire,
      host: res.data.host,
      accessId: res.data.accessKeyId,
      policy: res.data.policy,
      signature: res.data.signature,
    };
  };

  const init = async () => {
    try {
      const result = await getOSSData();
      setOSSData(result);
    } catch (error) {
      message.error(error as string);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleChange: UploadProps["onChange"] = ({ fileList }) => {
    console.log("Aliyun OSS:", fileList);
    onChange?.([...fileList]);
  };

  const onRemove = (file: UploadFile) => {
    const files = (value || []).filter((v) => v.url !== file.url);

    if (onChange) {
      onChange(files);
    }
  };

  const getExtraData: UploadProps["data"] = (file) => ({
    key: file.url,
    OSSAccessKeyId: OSSData?.accessId,
    policy: OSSData?.policy,
    Signature: OSSData?.signature,
  });

  const beforeUpload: UploadProps["beforeUpload"] = async (file) => {
    if (!OSSData) return false;

    const expire = Number(OSSData.expire) * 1000;

    if (expire < Date.now()) {
      await init();
    }

    const suffix = file.name.slice(file.name.lastIndexOf("."));
    const filename = Date.now() + suffix;
    // @ts-ignore
    file.url = `${OSSData.dir}/${filename}`;
    // file.url = `https://tcm-applet.zhi-yuan.net/v1/aliyun/authorization?objectKey=${OSSData.dir}/${filename}`;

    return file;
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const handlePreview = async (file: UploadFile) => {
    if (file.url?.startsWith("http")) {
      setPreviewImage(file.url);
    } else {
      if (!file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType);
      }
      setPreviewImage(file.preview as string);
    }
    setPreviewOpen(true);
  };
  const uploadProps: UploadProps = {
    name: "file",
    fileList: value,
    action: OSSData?.host,
    onChange: handleChange,
    onRemove,
    data: getExtraData,
    beforeUpload,
    onPreview: handlePreview,
  };

  return (
    <>
      <Upload
        listType="picture-card"
        accept="image/*"
        maxCount={10}
        {...uploadProps}
      >
        <button style={{ border: 0, background: "none" }} type="button">
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>上传处方</div>
        </button>
      </Upload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: "none" }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(""),
          }}
          src={previewImage}
        ></Image>
      )}
    </>
  );
};
