import { Modal } from "antd";
import { useEffect,useState, } from "react";
import { useNavigate } from "react-router-dom";
import img from "../../../src/assets/img/headUrl.png"

import { AliyunOSSUpload } from "../../components/AliyunOSSUpload";
import '../mobile/Mine.css'
import { CloseOutlined } from "@ant-design/icons";
import {useLocalStorageState} from "ahooks";
import {ChangePassword} from "./ChangePassword";

export default function Mine({ selectActive }: any) {
    const [modelShow, setModelShow] = useState(false);
    const [userStore, setUserStore] = useLocalStorageState<{
        id: string;
        name: string;
        telephone?: string;
    }>("user");
    const navigate = useNavigate();

    const [modal, contextHolder] = Modal.useModal();
    function changePassword() {
        modal.confirm({
            width: 400,
            closable: true,
            maskClosable: true,
            title: "修改密码",
            content: <ChangePassword />,
            footer: null,
        });
    }
    function loginOut() {
        setUserStore(undefined);
        navigate("/login", { replace: true });
    }
    // console.log(selectActive)
    return(
        <>
            <div className="mineBox">
                <div className="detailTitle">
                    {/*<LeftOutlined className="icon-detail" onClick={() => {*/}
                    {/*    goBack()*/}
                    {/*}}/>*/}
                    <div className="title">
                        我的
                    </div>
                </div>
                <div className="mine">
                    <div className="mineImg">
                        <img src={img} alt="" className="mineImg"/>
                    </div>
                    <div>{userStore?.name}</div>
                </div>
                <div className="takeModel" onClick={
                    // setModelShow(true)
                    changePassword
                }>
                    修改密码
                </div>
                <div className="takeModel" onClick={loginOut}>
                    退出
                </div>
            </div>
            <div className={["model", (modelShow ? "show fade-in" : "notShow")].join(' ')}>
                <div className="mineBack">
                    <div className="title">
                        修改密码
                        <div><CloseOutlined onClick={() => {
                            setModelShow(false);
                        }}/></div>
                    </div>
                    <div className="form">
                        <div className="formItem">
                            <div className="leftText">旧密码：</div>
                            <input/>
                        </div>
                        <div className="formItem">
                            <div className="leftText">新密码：</div>
                            <input/>
                        </div>
                        <div className="formItem">
                            <div className="leftText">确认密码：</div>
                            <input/>
                        </div>
                    </div>
                    <div className="success">
                        <div className="cancel">取消</div>
                        <div className="determin">确定</div>
                    </div>
                </div>
            </div>
            {contextHolder}
        </>
    )
}